.post-comment .comment-header, .post-comment .comment-body {
  color: #6B717F;
  font: 100% Arial; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-variant: small-caps;
  color: #6B717F;
  font-weight: bold; }

h1 {
  font-size: 30px;
  font-weight: 900; }

u {
  text-decoration: underline; }

.post-comment {
  background-color: #F8F9FA;
  border-radius: 5px;
  border: solid 1px #6B717F;
  border-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  display: flex;
  margin: 10px 0px;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box; }
  .post-comment .comment-header {
    display: flex;
    flex-direction: column;
    width: 100px;
    font-weight: 600;
    justify-content: center;
    padding: 10px;
    text-decoration: none; }
    .post-comment .comment-header .comment-profile-pic {
      background-color: #F8F9FA;
      border-radius: 5px;
      border: solid 1px #6B717F;
      border-color: rgba(0, 0, 0, 0.2);
      width: 60px;
      border-radius: 100%;
      align-self: center; }
    .post-comment .comment-header .comment-username {
      text-align: center; }
  .post-comment .comment-body {
    font-size: 18px;
    word-wrap: break-word;
    width: calc(100% - 100px);
    box-sizing: border-box; }
    .post-comment .comment-body p {
      word-wrap: break-word;
      padding: 10px;
      width: 100%;
      box-sizing: border-box; }

@media (max-width: 768px) {
  .post-comment {
    flex-direction: column; }
    .post-comment .comment-header {
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      padding: 0px 10px;
      text-decoration: none; }
      .post-comment .comment-header .comment-profile-pic {
        display: none; }
    .post-comment .comment-body {
      width: 100%; } }
