html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

#header, #header #title, #header nav .nav-link, #header-container > nav .nav-link {
  color: #6B717F;
  font: 100% Arial; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-variant: small-caps;
  color: #6B717F;
  font-weight: bold; }

h1 {
  font-size: 30px;
  font-weight: 900; }

u {
  text-decoration: underline; }

#header {
  z-index: 3;
  display: flex;
  width: 100%;
  position: sticky;
  height: 80px;
  top: 0px;
  background-color: #74F999;
  justify-content: space-around;
  align-items: center; }
  #header #title {
    font-family: Work Sans, sans-serif;
    font-size: 41px;
    font-variant: small-caps;
    text-decoration: none; }
  #header #hamburger {
    display: none; }
  #header nav {
    display: flex; }
    #header nav .nav-link {
      margin: 10px;
      text-decoration: none;
      cursor: pointer; }
      #header nav .nav-link:hover {
        color: #F8F9FA; }
      #header nav .nav-link:active {
        color: #6B717F; }

#header-container {
  width: 100%;
  position: sticky;
  top: 0px; }
  #header-container > nav {
    display: flex;
    width: 100%;
    flex-direction: column; }
    #header-container > nav .nav-link {
      padding: 3px;
      text-decoration: none;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      background-color: #F8F9FA;
      padding: 14px 16px;
      font-size: 17px; }
      #header-container > nav .nav-link:hover {
        background-color: #6B717F;
        color: #F8F9FA; }
    #header-container > nav .mobile-links {
      display: none; }

.hidden {
  display: none; }

@media (max-width: 768px) {
  #header-container #hamburger {
    display: inline-block;
    cursor: pointer;
    border: 2px solid grey;
    padding: 5px 9px;
    border-radius: 5px;
    font-size: 30px; }
  #header-container header nav {
    display: none; }
  #header-container nav .mobile-links {
    display: inline-block; } }
