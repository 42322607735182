#new-post #form-container form label, #new-post #form-container form #text textarea, #new-post #form-container form #text #text-info {
  color: #6B717F;
  font: 100% Arial; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-variant: small-caps;
  color: #6B717F;
  font-weight: bold; }

h1 {
  font-size: 30px;
  font-weight: 900; }

u {
  text-decoration: underline; }

#new-post {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px; }
  #new-post #form-container {
    width: 80%;
    max-width: 800px;
    background-color: #F8F9FA;
    border-radius: 5px;
    border: solid 1px #6B717F;
    border-color: rgba(0, 0, 0, 0.2);
    padding: 30px; }
    #new-post #form-container form {
      display: flex;
      flex-direction: column; }
      #new-post #form-container form .form-label {
        font-weight: bold; }
      #new-post #form-container form label {
        margin: 10px; }
      #new-post #form-container form span {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px; }
      #new-post #form-container form #text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 0px; }
        #new-post #form-container form #text textarea {
          width: 100%;
          height: 200px;
          resize: none;
          font-size: 24px;
          background-color: #F8F9FA;
          border-radius: 5px;
          border: solid 1px #6B717F;
          border-color: rgba(0, 0, 0, 0.2);
          background-color: white; }
        #new-post #form-container form #text #text-info {
          text-align: end; }
      #new-post #form-container form #btn-container {
        margin: 0px; }

@media (max-width: 768px) {
  #new-post #form-container {
    width: 90%; } }

@media screen and (max-width: 500px) {
  #new-post #form-container {
    width: 95%; } }
