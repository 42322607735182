.err-cont .error, .post .post-header, .post .post-header .post-profile .post-username, .post .post-body p, .post .post-footer .count {
  color: #6B717F;
  font: 100% Arial; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-variant: small-caps;
  color: #6B717F;
  font-weight: bold; }

.err-cont .error {
  width: 80%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  margin-bottom: 10px;
  border-radius: 20px;
  border: solid 1px red;
  background-color: pink;
  flex-direction: column; }
  .err-cont .error #err {
    font-weight: bold; }
  .err-cont .error .err {
    font-weight: bold; }
  .err-cont .error img {
    max-width: 400px; }

h1 {
  font-size: 30px;
  font-weight: 900; }

u {
  text-decoration: underline; }

.post {
  background-color: #F8F9FA;
  border-radius: 5px;
  border: solid 1px #6B717F;
  border-color: rgba(0, 0, 0, 0.2);
  max-width: 800px;
  width: 80%;
  margin-top: 10px; }
  .post .post-header {
    display: flex;
    height: 80px;
    padding: 10px 30px;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    font-weight: 600; }
    .post .post-header .post-link {
      text-decoration: none; }
    .post .post-header .post-profile {
      display: flex;
      align-items: center; }
      .post .post-header .post-profile img {
        width: 60px;
        border-radius: 100%;
        margin-right: 20px;
        border-radius: 100%;
        align-self: center;
        border: solid 1px black;
        border-color: rgba(0, 0, 0, 0.2); }
      .post .post-header .post-profile .post-username {
        font-weight: 600; }
    .post .post-header .post-date {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .post .post-header .post-date .post-follow-button {
        background-color: #F8F9FA;
        border-radius: 5px;
        border: solid 1px #6B717F;
        border-color: rgba(0, 0, 0, 0.2);
        background-color: #74F999;
        width: 75px;
        text-align: center;
        padding: 5px 7px;
        margin-top: 5px;
        cursor: pointer; }
      .post .post-header .post-date .post-follow-button-followed {
        background-color: #F8F9FA;
        border-radius: 5px;
        border: solid 1px #6B717F;
        border-color: rgba(0, 0, 0, 0.2);
        background-color: #6B717F;
        color: #74F999;
        border-color: #74F999;
        width: 75px;
        text-align: center;
        padding: 5px 7px;
        margin-top: 5px;
        cursor: pointer; }
  .post .post-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 50px 50px 50px;
    box-sizing: border-box; }
    .post .post-body .post-body-link {
      text-decoration: none;
      width: 100%;
      display: flex;
      justify-content: center; }
      .post .post-body .post-body-link .content-img {
        max-height: 400px; }
    .post .post-body p {
      font-size: 24px;
      word-wrap: break-word;
      width: 100%; }
    .post .post-body .content-img {
      max-width: 100%;
      max-height: calc(100vh - 200px);
      align-self: center;
      justify-self: center; }
  .post .post-footer {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    font-size: 20px; }
    .post .post-footer .delete {
      cursor: pointer; }
    .post .post-footer .post-likes {
      padding: 0px 20px;
      display: flex;
      align-items: center; }
      .post .post-footer .post-likes .like-btn {
        cursor: pointer; }
    .post .post-footer .post-comments {
      padding: 0px 15px 0px 0px;
      display: flex;
      align-items: center; }
      .post .post-footer .post-comments .post-comment-link {
        color: black; }
    .post .post-footer .count {
      font-size: 16px;
      padding: 0px 4px; }

.err-cont {
  display: flex;
  justify-content: center;
  width: 100%; }

@media (max-width: 768px) {
  .post {
    width: 85%; }
    .post .post-body {
      padding: 20px; } }

@media (max-width: 500px) {
  .post {
    width: 95%; }
    .post .post-body {
      padding: 15px; } }
