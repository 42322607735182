#search-container #posts-flex #search-bar #search-button, #search-container #posts-flex #search-bar #search-input, #search-container #load-more {
  color: #6B717F;
  font: 100% Arial; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-variant: small-caps;
  color: #6B717F;
  font-weight: bold; }

h1 {
  font-size: 30px;
  font-weight: 900; }

u {
  text-decoration: underline; }

#search-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column; }
  #search-container #posts-flex {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px; }
    #search-container #posts-flex #search-bar {
      display: flex;
      background-color: #F8F9FA;
      border-radius: 5px;
      border: solid 1px #6B717F;
      border-color: rgba(0, 0, 0, 0.2);
      width: 80%;
      max-width: 700px;
      justify-content: center;
      padding: 5px;
      margin-top: 30px; }
      #search-container #posts-flex #search-bar #search-button {
        background-color: #F8F9FA;
        border-radius: 5px;
        border: solid 1px #6B717F;
        border-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
        margin-left: 10px; }
        #search-container #posts-flex #search-bar #search-button:active {
          background-color: #6B717F;
          color: white; }
      #search-container #posts-flex #search-bar #search-input {
        width: 100%;
        background-color: #F8F9FA;
        border-radius: 5px;
        border: solid 1px #6B717F;
        border-color: rgba(0, 0, 0, 0.2);
        background-color: white;
        height: 25px;
        font-size: 25px;
        padding: 2px 10px; }
  #search-container #load-more {
    background-color: #F8F9FA;
    border-radius: 5px;
    border: solid 1px #6B717F;
    border-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 10px 20px;
    font-weight: 600;
    margin-bottom: 40px; }
    #search-container #load-more:active {
      color: #F8F9FA;
      background-color: #6B717F; }
