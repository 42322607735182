#login #error, #login form, #login form span input {
  color: #6B717F;
  font: 100% Arial; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-variant: small-caps;
  color: #6B717F;
  font-weight: bold; }

#login #error {
  width: 80%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  margin-bottom: 10px;
  border-radius: 20px;
  border: solid 1px red;
  background-color: pink;
  flex-direction: column; }
  #login #error #err {
    font-weight: bold; }
  #login #error .err {
    font-weight: bold; }
  #login #error img {
    max-width: 400px; }

h1 {
  font-size: 30px;
  font-weight: 900; }

u {
  text-decoration: underline; }

#login {
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  #login form {
    background-color: #F8F9FA;
    border-radius: 5px;
    border: solid 1px #6B717F;
    border-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    max-width: 600px; }
    #login form span {
      width: 100%;
      max-width: 300px;
      min-width: 250px;
      display: inline-block;
      box-sizing: border-box;
      padding: 21px; }
      #login form span input {
        width: 100%;
        border-radius: 5px;
        color: black; }
    #login form #submit input {
      cursor: pointer; }
