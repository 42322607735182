#profile-container #profile-header #header-info, #profile-container #profile-form, #profile-container #profile-form form span input, #profile-container #profile-form form #form-bio textarea, #profile-container #profile-menu .menu-btn, #edit-cont button {
  color: #6B717F;
  font: 100% Arial; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-variant: small-caps;
  color: #6B717F;
  font-weight: bold; }

h1 {
  font-size: 30px;
  font-weight: 900; }

u {
  text-decoration: underline; }

#profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  #profile-container #profile-header {
    width: 80%;
    max-width: 800px;
    background-color: #F8F9FA;
    border-radius: 5px;
    border: solid 1px #6B717F;
    border-color: rgba(0, 0, 0, 0.2);
    margin-top: 40px;
    display: flex;
    padding: 25px 0px;
    box-sizing: border-box; }
    #profile-container #profile-header #profile-picture {
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      background-color: #F8F9FA;
      border-radius: 5px;
      border: solid 1px #6B717F;
      border-color: rgba(0, 0, 0, 0.2);
      border-width: 0px 1px 0px 0px;
      border-radius: 0px; }
      #profile-container #profile-header #profile-picture #profile-username {
        text-align: center;
        font-size: 1em;
        word-wrap: break-word;
        word-break: break-all; }
      #profile-container #profile-header #profile-picture img {
        background-color: #F8F9FA;
        border-radius: 5px;
        border: solid 1px #6B717F;
        border-color: rgba(0, 0, 0, 0.2);
        border-radius: 100%;
        max-width: 120px;
        max-height: 120px;
        width: 120px;
        height: 120px;
        background-image: url(https://litter.s3-us-west-2.amazonaws.com/defaults/default-user.png);
        background-size: contain;
        background-repeat: no-repeat; }
    #profile-container #profile-header #header-info {
      padding: 10px;
      padding-left: 30px;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box; }
      #profile-container #profile-header #header-info #full-name {
        font-size: 25px; }
      #profile-container #profile-header #header-info #header-bio {
        width: 100%;
        box-sizing: border-box; }
        #profile-container #profile-header #header-info #header-bio p {
          min-height: 123px;
          padding: 10px 0px;
          box-sizing: border-box;
          width: 100%;
          word-break: break-word; }
      #profile-container #profile-header #header-info #header-stats {
        display: flex;
        justify-content: space-around; }
        #profile-container #profile-header #header-info #header-stats .count-box {
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 25%; }
          #profile-container #profile-header #header-info #header-stats .count-box .count-title {
            font-weight: 600;
            margin-bottom: 4px; }
  #profile-container #profile-form {
    width: 80%;
    max-width: 800px;
    box-sizing: border-box;
    background-color: #F8F9FA;
    border-radius: 5px;
    border: solid 1px #6B717F;
    border-color: rgba(0, 0, 0, 0.2);
    margin-top: 40px;
    padding: 10px; }
    #profile-container #profile-form form {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      #profile-container #profile-form form span {
        display: flex;
        flex-direction: column;
        font-weight: 600;
        box-sizing: border-box; }
        #profile-container #profile-form form span input {
          background-color: #F8F9FA;
          border-radius: 5px;
          border: solid 1px #6B717F;
          border-color: rgba(0, 0, 0, 0.2);
          background-color: white;
          padding: 5px; }
      #profile-container #profile-form form .small-inp {
        width: 50%;
        min-width: 185px;
        padding: 10px; }
      #profile-container #profile-form form #form-bio {
        width: 100%;
        padding: 10px; }
        #profile-container #profile-form form #form-bio textarea {
          resize: none;
          background-color: #F8F9FA;
          border-radius: 5px;
          border: solid 1px #6B717F;
          border-color: rgba(0, 0, 0, 0.2);
          background-color: white;
          height: 80px;
          padding: 5px; }
        #profile-container #profile-form form #form-bio #bio-footer {
          width: 100%;
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
          justify-content: flex-end;
          padding: 5px 0px 0px 20px; }
          #profile-container #profile-form form #form-bio #bio-footer #button {
            width: 85px;
            font-weight: 600;
            text-align: center;
            cursor: pointer; }
          #profile-container #profile-form form #form-bio #bio-footer #cancel {
            width: 85px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            background-color: #F8F9FA;
            border-radius: 5px;
            border: solid 1px #6B717F;
            border-color: rgba(0, 0, 0, 0.2);
            background-color: white;
            padding: 5px;
            margin-left: 5px; }
          #profile-container #profile-form form #form-bio #bio-footer #form-count {
            padding-right: 10px; }
  #profile-container #profile-menu {
    width: 80%;
    max-width: 800px;
    background-color: #F8F9FA;
    border-radius: 5px;
    border: solid 1px #6B717F;
    border-color: rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    height: 30px;
    display: flex;
    justify-content: space-around; }
    #profile-container #profile-menu .menu-btn {
      width: 100%;
      text-align: center;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      #profile-container #profile-menu .menu-btn.selected {
        font-weight: 600; }
  #profile-container #content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    #profile-container #content-container .post-comment {
      width: 80%;
      max-width: 800px; }
    #profile-container #content-container .links {
      width: 100%;
      display: flex;
      justify-content: center;
      text-decoration: none; }
    #profile-container #content-container .post-links {
      width: 80%;
      max-width: 800px;
      text-decoration: none;
      color: black;
      cursor: default; }
      #profile-container #content-container .post-links .post {
        width: 100%; }

@media (max-width: 768px) {
  #profile-container #profile-header {
    width: 90%;
    margin-top: 20px;
    padding: 5px; }
    #profile-container #profile-header #profile-picture {
      padding: 10px; }
      #profile-container #profile-header #profile-picture img {
        width: 90px;
        height: 90px; }
    #profile-container #profile-header #header-info {
      padding: 10px; }
  #profile-container #profile-form {
    width: 90%;
    margin-top: 20px;
    padding: 5px; }
  #profile-container #profile-menu {
    width: 90%; }
  #profile-container #content-container .post-comment {
    width: 90%; }
  #profile-container #content-container .post-links {
    width: 90%; } }

#edit-cont {
  width: 100%;
  display: flex;
  justify-content: flex-end; }
  #edit-cont button {
    background-color: #F8F9FA;
    border-radius: 5px;
    border: solid 1px #6B717F;
    border-color: rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 5px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 600; }

@media (max-width: 500px) {
  #edit-cont {
    justify-content: center; }
  #profile-container #profile-header {
    width: 95%;
    margin-top: 10px;
    flex-direction: column; }
    #profile-container #profile-header #profile-picture {
      align-items: center;
      border: none; }
  #profile-container #profile-form {
    width: 95%;
    margin-top: 10px; }
  #profile-container #header-info #full-name {
    text-align: center; }
  #profile-container #header-info #header-bio p {
    text-align: center; }
  #profile-container #profile-menu {
    width: 95%; }
  #profile-container #content-container .post-comment {
    width: 95%; }
  #profile-container #content-container .post-links {
    width: 95%; } }
