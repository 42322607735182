.post-container > span .back-btn, .post-container #comment-container #comment-form, .post-container #comment-container #comment-form #comment-text {
  color: #6B717F;
  font: 100% Arial; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-variant: small-caps;
  color: #6B717F;
  font-weight: bold; }

h1 {
  font-size: 30px;
  font-weight: 900; }

u {
  text-decoration: underline; }

.post-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .post-container > span {
    width: 80%;
    display: flex;
    max-width: 800px; }
    .post-container > span .back-btn {
      background-color: #F8F9FA;
      border-radius: 5px;
      border: solid 1px #6B717F;
      border-color: rgba(0, 0, 0, 0.2);
      padding: 6px;
      font-weight: 600;
      text-align: center;
      display: flex;
      align-items: center;
      margin-top: 35px;
      cursor: pointer; }
  .post-container #comment-container {
    width: 80%;
    display: flex;
    max-width: 800px;
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 70px; }
    .post-container #comment-container #comment-form {
      background-color: #F8F9FA;
      border-radius: 5px;
      border: solid 1px #6B717F;
      border-color: rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 10px;
      margin-bottom: 10px; }
      .post-container #comment-container #comment-form label {
        width: 95%;
        font-weight: bold;
        font-size: 18px; }
      .post-container #comment-container #comment-form #comment-text {
        resize: none;
        width: 95%;
        background-color: #F8F9FA;
        border-radius: 5px;
        border: solid 1px #6B717F;
        border-color: rgba(0, 0, 0, 0.2);
        background-color: white;
        font-size: 18px;
        margin: 10px 0px; }
      .post-container #comment-container #comment-form #submit-container {
        width: 95%;
        display: flex;
        box-sizing: border-box;
        justify-content: flex-end;
        align-items: center; }

@media (max-width: 768px) {
  .post-container > span {
    width: 85%; }
  .post-container #comment-container {
    width: 85%; } }

@media (max-width: 500px) {
  .post-container > span {
    width: 95%; }
  .post-container .back-btn {
    width: 100%; }
  .post-container #comment-container {
    width: 95%; } }
