#home-container #home-header p {
  color: #6B717F;
  font: 100% Arial; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-variant: small-caps;
  color: #6B717F;
  font-weight: bold; }

h1 {
  font-size: 30px;
  font-weight: 900; }

u {
  text-decoration: underline; }

#home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  #home-container #home-header {
    width: 80%;
    max-width: 800px;
    background-color: #F8F9FA;
    border-radius: 5px;
    border: solid 1px #6B717F;
    border-color: rgba(0, 0, 0, 0.2);
    margin-top: 30px; }
    #home-container #home-header h1 {
      padding: 10px 0px 0px 20px;
      font-size: 40px;
      color: #74F999; }
    #home-container #home-header p {
      padding: 20px;
      padding-top: 0px;
      font-size: 1.3em; }
